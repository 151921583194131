import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
];
export const GetProtectedRoutes = (roleList) => {
  
  const routesList = roleList
    .filter((role) => role.menuName && role.path && role.isLeaveNode === true)
    .map((role) => ({
      key: role.menuName.toLowerCase(),
      path: `${APP_PREFIX_PATH}/${role.path}`,
      component: React.lazy(() =>
        import(`views/app-views/${role.path}/${role.path}`)
      ),
    }));
  routesList.push({
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/dashboards")),
  });
  routesList.push({
    key: "DonorView",
    path: `${APP_PREFIX_PATH}/DonorView/:id`,
    component: React.lazy(() => import("views/app-views/Donors/DonorView")),
  });
  routesList.push({
    key: "AddDonation",
    path: `${APP_PREFIX_PATH}/AddDonation/:id`,
    component: React.lazy(() => import("views/app-views/Donations/AddDonation")),
  });
  routesList.push({
    key: "AddDonationInKind",
    path: `${APP_PREFIX_PATH}/AddDonationInKind/:id`,
    component: React.lazy(() => import("views/app-views/In-KindDonation/AddDonationInKind")),
  });

  routesList.push({
    key: "change-password",
    path: `${AUTH_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/change-password")
    ),
  });
  return routesList;
};
