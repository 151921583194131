import React, { createContext, useContext, useReducer, useEffect } from "react";
import { API_BASE_URL } from "configs/AppConfig";
import { useState } from "react";
import {
  COMPANY_ID,
  FACTORY_ID,
  USER_ID,
  USERPERMISSIONLIST,
  ROLESLIST,
} from "constants/AuthConstant";

export const UserDataContext = createContext();

const initialState = {
  companies: [],
  factories: [],
  newRoles: [],
  factoryRoles: [],
  userPermissions: [],
  users: [],
  companyId: null,
  notifications: [],
};
const userReducer = (state, action) => {

  switch (action.type) {
    case "UPDATE_PERMISSIONS":
      return {
        ...state,
        users: action.payload.users,
        newRoles: action.payload.newRoles,
        userPermissions: action.payload.usersPermission,
      };
    case "UPDATE_COMPANY_FACTORY_LIST":

      return {
        ...state,
        companies: action.payload.companies,
        factories: action.payload.factories,
      };

    case "UPDATE_COMPANY":
      return { ...state, companyId: action.payload };
    case "UPDATE_FACTORY":
      return { ...state, factoryId: action.payload };
    case "UPDATE_NOTIFICATIONS":
      return { ...state, notifications: action.payload };
    case "UPDATE_FACTORY_ROLES":
      return { ...state, factoryRoles: action.payload };
    default:

      return state;
  }
};
export const updateUserPermissions = (dispatch, userPermissions) => {

  dispatch({
    type: "UPDATE_PERMISSIONS",
    payload: userPermissions,
  });
};
export const setCompanyId = (dispatch, companyId) => {

  dispatch({
    type: "UPDATE_COMPANY",
    payload: companyId,
  });
  localStorage.setItem(COMPANY_ID, companyId);
  //localStorage.removeItem(FACTORY_ID);
};
export const setCompanyFactories = (dispatch, data) => {

  dispatch({
    type: "UPDATE_COMPANY_FACTORY_LIST",
    payload: data,
  });
};
export const setFactoryId = (dispatch, factoryId) => {

  dispatch({
    type: "UPDATE_FACTORY",
    payload: factoryId,
  });
  localStorage.setItem(FACTORY_ID, factoryId);
};
export const setFactoryRoles = (dispatch, factoryRoles) => {

  dispatch({
    type: "UPDATE_FACTORY_ROLES",
    payload: factoryRoles,
  });
};
export const setNotifications = (dispatch, notifications) => {
  dispatch({
    type: "UPDATE_NOTIFICATIONS",
    payload: notifications,
  });
};

export function UserDataProvider({ children }) {
  const [userData, dispatch] = useReducer(userReducer, initialState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateUserPermissionsWithContext = (userPermissions) => {
    updateUserPermissions(dispatch, userPermissions);
  };
  const setCompanyFactoriesWithContext = (data) => {
    setCompanyFactories(dispatch, data);
  };
  const setCompanyIdWithContext = (companyId) => {
    setCompanyId(dispatch, companyId);
  };
  const setFactoryIdWithContext = (factoryId) => {
    setFactoryId(dispatch, factoryId);
  };
  const setFactoryRolesWithContext = (factoryRoles) => {

    setFactoryRoles(dispatch, factoryRoles);
  };

  const setNotificationsWithContext = (notifications) => {
    setNotifications(dispatch, notifications);
  };
  const userId = localStorage.getItem(USER_ID);
  useEffect(() => {
    const fetchData = async () => {

      try {
        if (userId) {

          const response = await fetch(
            `${API_BASE_URL}/UserManagement/FetchUser?userId=${userId}`,
            {
              method: "GET",
            }
          );
          if (!response.ok) {
            console.log(response);
          }
          console.log(response);
          const data = await response.json();
          dispatch({ type: "UPDATE_PERMISSIONS", payload: data });
          if (data.companies)//&& data.factories) 
          {
            const selectedCompanyId = localStorage.getItem(COMPANY_ID);
            //const selectedFactoryId = localStorage.getItem(FACTORY_ID);

            const companyId_ =
              selectedCompanyId ||
              (data.companies.length > 0 && data.companies[0].companyId);

            // const factoryId_ =
            //   selectedFactoryId ||
            //   (data.companies.length > 0 &&
            //     data.factories.find(
            //       (x) => x.companyId === data.companies[0].companyId
            //     )?.factoryRegisterationId);

            const factoryRoles = data.newRoles.filter(
              (x) => x.companyId == companyId_ //&& x.factoryId == factoryId_
            );
            dispatch({
              type: "UPDATE_COMPANY_FACTORY_LIST",
              payload: data,
            });
            dispatch({
              type: "UPDATE_COMPANY",
              payload: companyId_,
            });
            // dispatch({
            //   type: "UPDATE_FACTORY",
            //   payload: factoryId_,
            // });
            dispatch({
              type: "UPDATE_FACTORY_ROLES",
              payload: factoryRoles,
            });
            dispatch({
              type: "UPDATE_NOTIFICATIONS",
              payload: data.notifications,
            });
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <UserDataContext.Provider
      value={{
        ...userData,
        updateUserPermissions: updateUserPermissionsWithContext,
        setCompanyFactories: setCompanyFactoriesWithContext,
        setCompanyId: setCompanyIdWithContext,
        // setFactoryId: setFactoryIdWithContext,
        setFactoryRoles: setFactoryRolesWithContext,
        setNotifications: setNotificationsWithContext,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}
export function useUserData() {

  const context = useContext(UserDataContext);
  if (!context) {
    throw Error("useUserData must be used within a UserDataProvider");
  }
  return context;
}
