import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  // alert("Auth service");
  // alert(data);
  console.log("Auth service");
  console.log(data);
  return fetch({
    url: "/Account/login",
    method: "post",
    data: data,
  });
};


AuthService.changepassword = function (data){
  // alert("Authservice");
return fetch({
  url:"/Account/changepassword",
  method:"post",
  data:data,
  });
};


AuthService.ClientRegistation = function (data){
  alert("ClinentEdit");
  console.log("Authservice client Registration");
  console.log(data);
return fetch({
  url:"/Account/Client-Registration",
  method:"post",
  data:data,
  });
};




AuthService.ClientEdit = function (data){
  alert("ClinentEdit");
  console.log("Authservice client Registration");
  console.log(data);
return fetch({
  url:"/Account/Client-EditForm",
  method:"post",
  data:data,
  });
};







AuthService.forgotPassword = function (data){
  // alert("Authservice");
  // console.log("Authservice");
  // console.log(data);
return fetch({
  url:"/Account/forgot-password",
  method:"post",
  // contentType: "application/json",
  data:data,
  });
};

AuthService.resetPassword = function (data){
  alert("Authservice");
  console.log("Authservice");
  console.log(data);
return fetch({
  url:"/Account/reset-password",
  method:"post",
  data:data,
  });
};



AuthService.register = function (data) {
	data.Roles = ["Role1", "Role2"];
  // alert("AuthService");
  
  return fetch({
    url: "/Account/register",
    method: "post",
    data: data,
  });
};

AuthService.logout = function () {
  return fetch({
    url: "/auth/logout",
    method: "post",
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

export default AuthService;
